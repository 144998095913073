import * as React from "react";
import { graphql } from "gatsby";
import { useMemo, useState } from "react";
import { useArticleOrdered, useSideBarScroll } from "../lib/hooks";

// components
import ArticleCard from "../components/articlecard";
import CategoryHeader from "../components/categoryheader";
import HeadTitle from "../components/headtitle";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ThemeImmoSideBar from "../components/theme-immo-sidebar";
import Icon from "../components/icon";

// types & interfaces
import type { Category, TemplatePageProps, ThemeImmo } from "../lib/types";
import { clsx } from "@monemprunt/design-system";

interface ThemeImmoPageData {
  theme: ThemeImmo;
  parent: { subjects: Category[] };
}

const ThemeImmoPage: React.FC<TemplatePageProps<ThemeImmoPageData>> = ({
  data: {
    theme,
    parent: { subjects },
  },
  location: { pathname },
}) => {
  subjects = [theme, ...subjects];

  return (
    <Layout active={"pages/guide-immo"}>
      <Seo
        {...theme.SEO}
        image={theme.image?.localFile.publicURL}
        pathname={pathname}
      />
      <main className="mt-15 sm:mt-24">
        <CategoryHeader
          title={theme.name}
          cover={theme.image}
          className={"hidden sm:block"}
        />
        <ContentSection theme={theme} subjects={subjects} />
      </main>
    </Layout>
  );
};

export default ThemeImmoPage;

const ContentSection: React.FC<{
  theme: ThemeImmo;
  subjects: Array<Category>;
}> = ({ theme, subjects }) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const subjectsToShow = useMemo(
    () => subjects.filter(({ articles }) => articles.length > 0),
    [subjects]
  );

  let selectedSubject = useSideBarScroll(subjectsToShow);

  return (
    <section className={"grid grid-cols-10 xl:grid-cols-12"}>
      {/* SideBar */}
      <div
        className={clsx(
          "h-full pt-24 bg-light-50 pb-64",
          "sm:pb-80 ",
          "lg:col-span-3",
          "xl:col-span-3",
          {
            "col-span-10": sideBarOpen,
            "col-span-2": !sideBarOpen,
          }
        )}>
        <ThemeImmoSideBar
          isOpen={sideBarOpen}
          className={clsx(`w-full lg:sticky lg:top-40`, {
            "sticky top-40": !sideBarOpen,
          })}
          activeThemeSlug={theme.SEO.slug}
          selectedSubjectSlug={selectedSubject}
          onClose={() => setSideBarOpen(false)}
          onThemeClicked={_ => {
            setSideBarOpen(true);
            scroll({
              top: document.querySelector("main").offsetTop,
              behavior: "smooth",
            });
          }}
        />
      </div>

      {/*Subjects */}
      <section
        className={clsx(
          "mt-24 col-span-8 space-y-12",
          "lg:block lg:col-span-7",
          "xl:col-span-9",
          {
            hidden: sideBarOpen,
          }
        )}>
        {subjectsToShow.map((subject, index) => (
          <Subject
            className={index === subjectsToShow.length - 1 && "pb-40"}
            key={subject.id}
            subject={subject}
            themeSlug={theme.SEO.slug}
          />
        ))}
      </section>
    </section>
  );
};

const Subject: React.FC<{
  subject: Category;
  themeSlug: string;
  className?: string;
}> = ({ subject, themeSlug, className }) => {
  const [displayAll, setDisplayAll] = useState(false);

  const articlesOrdered = useArticleOrdered(subject.articles);
  const toShow = displayAll ? articlesOrdered : articlesOrdered.slice(0, 3);

  return (
    <>
      <div
        id={subject.SEO.slug}
        className={`${className} px-5 space-y-4  
        bg-gradient-to-b from-white to-light-50 
        md:px-10
        lg:px-15
        `}>
        {/*Title & description of subject*/}
        <HeadTitle as="h2" className="text-xl italic" label={subject.name} />
        <p>{subject.SEO.description}</p>

        {/* Articles */}
        <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4 xl:gap-8 lg:grid-cols-3 xxl:gap-16">
          {toShow.map(article => (
            <li key={`article ${article.id}`}>
              <ArticleCard
                className={"md:text-sm"}
                article={article}
                categorySlug={`/guide-immo/${themeSlug}${
                  subject?.SEO.slug !== themeSlug ? `/${subject?.SEO.slug}` : ""
                }`}
              />
            </li>
          ))}
        </ul>

        {/* "Show more" button */}
        <div
          className={`
          w-full flex items-center justify-center
          ${subject.articles.length <= 3 && "h-20"}
        `}>
          {subject.articles.length > 3 && (
            <button
              aria-label={"Voir plus d'articles"}
              className={`text-secondary-100 text-center flex flex-col
              justify-center items-center space-y-2 pb-4
              ${
                displayAll
                  ? "hover:animate-bounce-up active:animate-bounce-up"
                  : "hover:animate-bounce-down active:animate-bounce-down"
              }`}
              onClick={_ => setDisplayAll(!displayAll)}>
              {displayAll ? (
                <>
                  <div> Voir moins</div>
                  <Icon icon={"arrow-up-dots"} />
                </>
              ) : (
                <>
                  <div> Voir plus</div>
                  <Icon icon={"arrow-down-dots"} />
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <div
        className={`w-full h-10 bg-gradient-to-t from-white to-light-50 !mt-0`}
      />
    </>
  );
};

// Gatsby query to get Data
export const query = graphql`
  query SingleTheme($id: Int) {
    theme: strapiCategorytree(strapiId: { eq: $id }) {
      id: strapiId
      name
      SEO {
        title
        slug
        description
      }
      articles {
        id
        title
        summary
        cover {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                width: 800
              )
            }
          }
        }
        published_date
        SEO {
          slug
        }
      }
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }

    parent: allStrapiCategorytree(
      filter: { strapiParent: { id: { eq: $id } } }
    ) {
      subjects: nodes {
        id: strapiId
        name
        articles {
          id
          title
          summary
          cover {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  width: 400
                )
              }
            }
          }
          published_date
          SEO {
            slug
          }
        }
        SEO {
          slug
        }
      }
    }
  }
`;
