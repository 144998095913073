import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import * as React from "react";
import { Category, ThemeImmo } from "../lib/types";
import { linkWithSlash } from "../lib/functions";
import { clsx, Sidebar } from "@monemprunt/design-system";

export interface ThemeSidebarProps {
  activeThemeSlug: string;
  selectedSubjectSlug?: string;
  onClose: () => void;
  onThemeClicked: (id: number) => void;
  defaultThemes?: Array<ThemeImmo>;
  isOpen?: boolean;
  className?: string;
}

interface ThemeList {
  guide: Category & {
    themes: Array<ThemeImmo>;
  };
}

const ThemeSidebar: React.FC<ThemeSidebarProps> = ({
  activeThemeSlug,
  selectedSubjectSlug,
  defaultThemes = [],
  className = null,
  isOpen = false,
  onClose,
  onThemeClicked
}) => {
  // query to get all the theme & subjects from "guide immo"
  let themes: ThemeImmo[];
  try {
    const result: ThemeList = useStaticQuery(graphql`
      query ThemeSideBar {
        guide: strapiCategorytree(name: { eq: "Guide de l'achat immobilier" }) {
          id: strapiId
          themes: subcategories {
            id
            name
            SEO {
              slug
            }
            articles {
              id
            }
            icon: sidebar_icon {
              localFile {
                publicURL
              }
            }
            subjects: subcategories {
              id
              name
              articles {
                id
              }
              SEO {
                slug
              }
            }
          }
        }
      }
    `);

    // get themes
    const {
      guide: { themes: categories }
    } = result;
    themes = categories;
  } catch (error) {
    themes = defaultThemes;
  }

  return (
    <div
      className={clsx(className, "bg-light-50 w-full", "lg:sticky lg:top-40", {
        "sticky top-40": !isOpen
      })}
      style={{ zIndex: 7 }}>
      <Sidebar
        onClose={onClose}
        className={clsx(
          "w-full h-[94vh] [&_ul]:max-h-[80vh] relative pt-2 px-5",
          "md:top-32",
          "lg:sticky lg:!bg-transparent lg:top-30",
          {
            "sticky top-28": !isOpen
          }
        )}
        open={isOpen}>
        {themes.map((theme) => (
          <ThemeLine
            theme={theme}
            key={theme.id}
            active={theme.SEO.slug === activeThemeSlug}
            childActive={selectedSubjectSlug}
            open={isOpen}
            onClose={onClose}
            onSelect={() => {
              onThemeClicked(theme.id);
            }}
          />
        ))}
      </Sidebar>
      {/* </ul>
            </nav> */}
    </div>
  );
};

const ThemeLine: React.FC<{
  theme: ThemeImmo;
  active: boolean;
  childActive?: string;
  open: boolean;
  onSelect: () => void;
  onClose: () => void;
}> = ({ theme, active, childActive, open, onSelect, onClose }) => {
  /**
   * This function is used to implement smooth scrolling when the
   * user clicks on a subject in the same theme
   */
  const handleSubjectClick = (subjectSlug: string, parentThemeSlug: string) => {
    const currentThemeSlug = window.location.pathname.split(
      "/pages/guide-immo/"
    )[1];
    if (currentThemeSlug !== parentThemeSlug || open) {
      navigate(
        `/pages/guide-immo/${linkWithSlash(parentThemeSlug)}#${subjectSlug}`
      );
    } else {
      // scroll to element
      let offsetTop = document.getElementById(subjectSlug).offsetTop;
      scroll({
        top: offsetTop - 150,
        behavior: "smooth"
      });
    }
    onClose();
  };

  const subjects = [theme, ...theme.subjects].filter(({ articles }) =>
    articles ? articles.length > 0 : true
  );

  return (
    <li
      className={`grid mt-8 ${
        open ? "grid-cols-10" : "grid-cols-2"
      } gap-4 lg:grid-cols-10`}>
      {/*Icon*/}
      <button
        title={theme.name}
        className={"col-span-2 flex justify-center"}
        onClick={onSelect}
        aria-label={`Sélectionner le thème ${theme.name}`}>
        <img
          src={theme.icon.localFile.publicURL}
          alt={`Icône ${theme.name}`}
          className={`object-contain filter h-15 ${
            !active && "grayscale opacity-25"
          }`}
        />
      </button>

      <div className={`col-span-8 self-start ${!open && "hidden"} lg:block`}>
        {/* Title */}
        <Link
          to={linkWithSlash(`/pages/guide-immo/${theme.SEO.slug}`)}
          className="font-bold uppercase text-sm">
          {theme.name}
        </Link>

        {/* Subjects */}
        <ul className={"mt-4"}>
          {subjects.map((subject) => (
            <li key={subject.id}>
              <a
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  handleSubjectClick(subject.SEO.slug, theme.SEO.slug);
                }}
                href={`/pages/guide-immo/${linkWithSlash(theme.SEO.slug)}#${
                  subject.SEO.slug
                }`}
                className={`text-sm mt-0 grid grid-cols-10 hover:text-secondary-100 ${
                  childActive !== subject.SEO.slug
                    ? "text-dark-50"
                    : "text-dark-100"
                }`}>
                <span
                  className={`col-span-1 border-l-2 ${
                    childActive === subject.SEO.slug
                      ? "border-secondary-100"
                      : "border-gray-400/30"
                  }`}
                />
                <span className={"col-span-9"}>{subject.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export default ThemeSidebar;
